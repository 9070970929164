






































































































import Vue from 'vue';
import axios from 'axios';
import {
  SmartTable,
  Config,
  State,
  Column,
  hasValue,
  Sorting,
  PagingOptions,
  SmartTableForm,
  SmartTableAddDialog
} from 'rey-vue-smarttable';
import Coating from '@/models/Coating';
import Station from '@/models/Station';
import StationLoader from '@/router/StationLoader';
import StationType from '@/models/StationType';
import EntityType from '@/models/EntityType';
import FieldDefinitionLoader from '@/services/FieldDefinitionLoader';
import AuthenticationService from '@/services/AuthenticationService';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });

export default Vue.extend({
  name: 'articles',
  components: { SmartTable, SmartTableAddDialog, SmartTableForm },
  data() {
    return {
      AuthenticationService: AuthenticationService,
      copiedElement: {},
      LoadState,
      smartTableState: new State(new Sorting('Number', 'ascending')),
      smartTableConfig: new Config(
        (row) => row.ArticleId,
        [
          new Column({
            title: this.$t('views.admin.articles.tableHeaders.id').toString(),
            fieldName: 'ArticleId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.admin.articles.tableHeaders.number').toString(),
            fieldName: 'Number',
            fieldType: 'string',
            editable: () => AuthenticationService.isAuthenticated,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 15,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 15
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.admin.articles.tableHeaders.defaultCoating').toString(),
            fieldName: 'DefaultCoatingId',
            fieldType: 'dropdown',
            editable: () => AuthenticationService.isAuthenticated,
            validator: hasValue
          }),
          new Column({
            title: this.$t(
              'views.admin.articles.tableHeaders.defaultLoadingStationNumber'
            ).toString(),
            fieldName: 'DefaultLoadingStationNumber',
            fieldType: 'dropdown',
            editable: () => AuthenticationService.isAuthenticated,
            dropdownOptions: [],
            defaultValue: 0
          }),
          new Column({
            title: this.$t(
              'views.admin.articles.tableHeaders.defaultMaskingStationNumber'
            ).toString(),
            fieldName: 'DefaultMaskingStationNumber',
            fieldType: 'dropdown',
            editable: () => AuthenticationService.isAuthenticated,
            dropdownOptions: [],
            defaultValue: 0
          }),
          new Column({
            title: this.$t(
              'views.admin.articles.tableHeaders.defaultUnloadingStationNumber'
            ).toString(),
            fieldName: 'DefaultUnloadingStationNumber',
            fieldType: 'dropdown',
            editable: () => AuthenticationService.isAuthenticated,
            dropdownOptions: [],
            defaultValue: 0
          })
        ],
        new Sorting('Number', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'Articles'),
      // eslint-disable-next-line
      selected: undefined as any,
      windowPartLoadState: LoadState.NotStarted
    };
  },
  async created() {
    await FieldDefinitionLoader.loadDynamicColumnDefinitionsToSmartTableConfig(
      EntityType.Article,
      this.smartTableConfig,
      true
    );
    await this.loadDropdownValues();
  },
  methods: {
    async loadDropdownValues() {
      await axios
        .get('/api/Coating', {
          params: {
            orderBy: 'coatingNumber'
          }
        })
        .then((response) => response.data)
        .then((result) => this.updateCoatings(result.entities));

      this.updateStations(
        StationLoader.Instance().getStations(StationType.Loading),
        'DefaultLoadingStationNumber'
      );
      this.updateStations(
        StationLoader.Instance().getStations(StationType.Unloading),
        'DefaultUnloadingStationNumber'
      );
      this.updateStations(
        StationLoader.Instance().getStations(StationType.Masking),
        'DefaultMaskingStationNumber'
      );
    },
    updateStations(stations: Station[], dropdown: string) {
      const values = stations.map((x) => ({
        id: x.stationNumber,
        title: this.$t(x.displayNameResourceId).toString()
      }));
      values.unshift({
        id: 0,
        title: this.$t('views.admin.articles.stationDropdownValue.auto').toString()
      });

      this.updateColumnDropdownOptions(dropdown, values);
    },
    updateCoatings(colors: Coating[]) {
      const values = colors.map((x) => ({
        id: x.coatingId,
        title: x.coatingNumber
      }));

      this.updateColumnDropdownOptions('DefaultCoatingId', values);
    },
    updateColumnDropdownOptions(
      fieldName: string,
      dropdownOptions: Array<{ id: string | number; title: string }>
    ) {
      if (this.smartTableConfig && this.smartTableConfig.columns) {
        for (const col of this.smartTableConfig.columns) {
          if (col.fieldName === fieldName) {
            col.dropdownOptions = dropdownOptions;
            break;
          }
        }
      }
    },
    startCopy: function () {
      this.copiedElement = JSON.parse(JSON.stringify(this.selected));
      // @ts-ignore
      this.copiedElement.Number = '';

      // @ts-ignore
      this.$refs.smartTableCopyDialog.show();

      // @ts-ignore
      document.getElementById('smartTableCopyDialogFormNumber')?.focus();
    },
    saveCopy: function () {
      if (
        // @ts-ignore
        this.$refs.smartTableCopyForm.validateAndUpdate()
      ) {
        // @ts-ignore
        this.$refs.smartTable.$_SmartTable_addElement(this.copiedElement);
        // @ts-ignore
        this.$refs.smartTableCopyDialog.hide();
      }
    },
    getIcons(currentValue: string): string[] {
      return JSON.parse(currentValue);
    },
    // eslint-disable-next-line
    isIconsArray(column: any, currentValue: string): boolean {
      if (!currentValue) {
        return false;
      }
      if (!currentValue.toString().startsWith('[')) {
        return false;
      }
      if (!currentValue.toString().endsWith(']')) {
        return false;
      }
      if (!currentValue.toString().includes('fa')) {
        return false;
      }
      try {
        JSON.parse(currentValue);
        return true;
      } catch {
        return false;
      }
    }
  }
});
